import * as React from 'react';

import { Heading } from '../../Layout/DeviceOverview/Heading';
import { Fog } from '../../Layout/DeviceOverview/Fog';
import { Clearing } from '../../Layout/DeviceOverview/Clearing';
import { Winter } from '../../Layout/DeviceOverview/Winter';
import { Statistics } from '../../Layout/DeviceOverview/Statistics';

import moment from 'moment';
import 'moment/locale/cs';
import CardGroup from 'reactstrap/lib/CardGroup';
import Container from 'reactstrap/lib/Container';
import { DeviceStates } from '../../../types';

function parseDate(inputDate: string): string {
  const date = new Date(parseInt(inputDate, 10));
  return moment(date)
    .locale('cs')
    .format('LTS');
}

function parseDay(inputDate: string): string {
  const date = new Date(parseInt(inputDate, 10));
  return moment(date)
    .locale('cs')
    .format('LL');
}

export const DeviceStateDisplay: React.SFC<DeviceStates.IDeviceQueryProps> = ({
  currentDevState: {
    settings,
    status,
    timestamp,
    temp,
    timers,
    errors,
    float_settings,
  },
}) => {
  return (
    <Container fluid={true}>
      <Heading
        date={parseDay(timestamp)}
        time={parseDate(timestamp)}
        power={status.napajeni}
        serviceMode={status.rezim_servis_DT1}
      />
      <CardGroup style={{ marginTop: '1rem' }}>
        <Fog
          material_na_presypu={status.material_na_presypu}
          prekrocen_cas_cekani_na_material={errors.neni_material_presyp}
          cerpadlo_v_chodu={status.cerpadlo_mlzeni}
          dosazen_min_tlak_za_cerpadlem={status.tlak_cerpadlo_mlzeni}
          dosazen_max_tlak_za_cerpadlem={errors.tlak_vody_mlzeni}
          cas_mlzeni={timers.mlzeni_zap}
          cas_prodlevy={timers.mlzeni_vyp}
          cas_mlzeni_setting={settings.doba_mlzeni_zap}
          cas_prodlevy_setting={settings.doba_mlzeni_vyp}
          cekani_na_mat={timers.cekani_na_mat}
        />
        <Clearing
          motohodiny_cisteni={float_settings.motohodiny_stav}
          motohodiny_settings={float_settings.motohodiny_profuk}
          ventil_profuku_otevren={status.ventil_profuku}
          dosazen_min_tlak_za_ventilem={status.ventil_profuku_tlak}
          cas_profuku={timers.profuk_zap}
          profuk_settings={settings.doba_profuk_zap}
        />
        <Winter
          aktualni_teplota={temp}
          teplota_zimniho_opatreni={float_settings.teplota_zima}
          actual_state={errors.tlak_vzduchu_profuk}
        />
      </CardGroup>
      <CardGroup>
        <Statistics />
      </CardGroup>
    </Container>
  );
};
