import * as React from 'react';
import { Card, Table } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';

import { TabHeader, RowNumber } from './TableParts';
import CardHeader from 'reactstrap/lib/CardHeader';
import { SendFloatCommand } from '../../Device/DeviceCommands/SendFloatCommand';
import { cardStyle } from './constants';
import { SetFloatTypes } from '../../../__generated__/globalTypes';

interface IFog {
  aktualni_teplota: number;
  teplota_zimniho_opatreni: number;
  actual_state: boolean;
}

export function resolveColor(actual: number, winter: number) {
  const diff = 1;
  const result = actual < winter;
  if (result) {
    return actual <= winter - diff;
  } else {
    return !(actual >= winter + diff);
  }
}

export const Winter: React.SFC<IFog> = ({
  aktualni_teplota,
  teplota_zimniho_opatreni,
  actual_state,
}) => (
  <Card
    color={actual_state ? 'info' : null}
    inverse={actual_state}
    style={cardStyle}
  >
    <CardHeader tag="h5">Zimní opatření</CardHeader>
    <CardBody className="d-flex flex-column">
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Stav" />
          <RowNumber title="Aktuální teplota (C)" status={aktualni_teplota} />
          <RowNumber
            title="Teplota zimního opatření (C)"
            status={teplota_zimniho_opatreni}
          />
        </tbody>
      </Table>
      <div className="mt-auto" style={{ marginBottom: '9.45rem' }}>
        <Table size="sm" borderless={true}>
          <tbody>
            <TabHeader title="Nastavení" />
          </tbody>
        </Table>
        <SendFloatCommand
          command={SetFloatTypes.TEPLOTA_ZIMA}
          title="Teplota zimního opatření (C)"
          min={-150}
          precision={0.5}
        />
      </div>
    </CardBody>
  </Card>
);
