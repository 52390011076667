import * as React from 'react';
import { Form, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { sendValueCommand } from '../queries/sendValueCommand';
import SendValueCommandMutation from '../queries/sendValueCommand';
import { SetValueTypes } from '../../../__generated__/globalTypes';

interface ISendCommand {
  command: SetValueTypes;
  title?: string;
  min: number;
}
interface ICommandState {
  input: HTMLInputElement | null;
}

class CommandComp extends React.Component<ISendCommand, ICommandState> {
  constructor(props: ISendCommand) {
    super(props);
    this.setNode = this.setNode.bind(this);
    this.state = {
      input: null,
    };
  }

  public render() {
    const { command, title, min } = this.props;
    const { input } = this.state;
    return (
      <SendValueCommandMutation mutation={sendValueCommand}>
        {sendMutation => (
          <Form
            // tslint:disable-next-line jsx-no-lambda
            onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
              e.preventDefault();
              const sendValue = parseInt(input.value, 10);
              if (!isNaN(sendValue)) {
                sendMutation({
                  variables: {
                    command,
                    device_id: 1,
                    value: sendValue,
                  },
                });
                // set defaut value
                input.value = null;
                this.setState({
                  input,
                });
              }
            }}
            style={{
              marginBottom: '0.5em',
              marginTop: '0.5em',
            }}
          >
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                {title || command}
              </InputGroupAddon>
              <Input innerRef={this.setNode} type="number" min={min} />
              <InputGroupAddon addonType="append">
                <Button color="primary" type="submit">
                  Nastavit
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
        )}
      </SendValueCommandMutation>
    );
  }

  private setNode(input: HTMLInputElement) {
    this.setState({ input });
  }
}
export const SendValueCommand = CommandComp;
