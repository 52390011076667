import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getDevice2 } from './__generated__/getDevice2';

export const getDevice2FloatsQuery = gql`
  query getDevice2 {
    getDevice2Ints {
      ValueID
      value
      deviceId
    }
    getDevice2Floats {
      value
      ValueID
      deviceId
    }
  }
`;

class GetDevice2FloatsQuery extends Query<getDevice2> {}

export default GetDevice2FloatsQuery;
