import * as React from 'react';
import { Card, Table } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import { SendBoolCommand } from '../../Device/DeviceCommands/SendBoolCommand';
import { SendFloatCommand } from '../../Device/DeviceCommands/SendFloatCommand';
import { SendValueCommand } from '../../Device/DeviceCommands/SendValueCommand';
import { cardStyle, controlsStyle, titleOffOnStyle } from './constants';
import { timePareserFunction } from './layoutUtils';
import { RowBool, RowNumber, TabHeader } from './TableParts';
import {
  CommandTypes,
  SetFloatTypes,
  SetValueTypes,
} from '../../../__generated__/globalTypes';

interface IFog {
  motohodiny_cisteni: number;
  ventil_profuku_otevren: boolean;
  dosazen_min_tlak_za_ventilem: boolean;
  cas_profuku: number;
  motohodiny_settings: number;
  profuk_settings: number;
}
const Controls = () => (
  <tr>
    <td style={titleOffOnStyle}>Profuk</td>
    <td style={controlsStyle}>
      <SendBoolCommand command={CommandTypes.PROFUK_ZAP} color="success" />
    </td>
    <td style={controlsStyle}>
      <SendBoolCommand command={CommandTypes.PROFUK_VYP} color="danger" />
    </td>
  </tr>
);

function resolveRunning(cd: number) {
  return cd > 0;
}

export const Clearing: React.SFC<IFog> = ({
  motohodiny_cisteni,
  ventil_profuku_otevren,
  dosazen_min_tlak_za_ventilem,
  cas_profuku,
  motohodiny_settings,
  profuk_settings,
}) => (
  <Card
    style={cardStyle}
    color={resolveRunning(cas_profuku) ? 'info' : null}
    inverse={resolveRunning(cas_profuku)}
  >
    <CardHeader tag="h5">Čištění</CardHeader>
    <CardBody className="d-flex flex-column">
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Stav" />
          <RowNumber
            title="Motohodiny čistění (h)"
            status={timePareserFunction(motohodiny_cisteni)}
            info={{
              unit: 'h',
              value: timePareserFunction(motohodiny_settings),
            }}
          />
          <RowBool
            title="Ventil profuku otevřen"
            status={ventil_profuku_otevren}
          />
          <RowBool
            title="Dosažen min. tlak za ventilem profuku"
            status={dosazen_min_tlak_za_ventilem}
          />
          <RowNumber
            title="Čas profuku (s)"
            status={cas_profuku}
            info={{
              unit: 's',
              value: profuk_settings,
            }}
          />
          <tr>
            <td />
          </tr>
        </tbody>
      </Table>
      <div className="mt-auto">
        <Table size="sm" borderless={true}>
          <tbody>
            <TabHeader title="Nastavení" />
          </tbody>
        </Table>
        <SendFloatCommand
          min={0}
          command={SetFloatTypes.MOTOHODINY_PROFUK}
          title="Motohodiny čištění (h)"
          precision={0.1}
        />
        <SendValueCommand
          min={0}
          command={SetValueTypes.DOBA_PROFUK_ZAP}
          title="Čas profuku (s)"
        />
      </div>
      <br />
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Manuální ovládání" />
          <Controls />
        </tbody>
      </Table>
    </CardBody>
  </Card>
);
