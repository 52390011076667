import moment from 'moment';
import * as React from 'react';
import Datetime from 'react-datetime';
import { Field, Form } from 'react-final-form';
import { Button, Form as BForm, InputGroup } from 'reactstrap';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import GetFlowDataMutation, { getFlowDataQuery } from '../queries/getFlowData';

function toIsoString(date: string) {
  return new Date(parseInt(date, 10)).toISOString();
}
const DisplayResult: React.SFC<{
  value: number;
  upperTimestamp: string;
  lowerTimestamp: string;
}> = ({ value, upperTimestamp, lowerTimestamp }) => {
  const time = value / 60;
  return (
    <div>
      <p>{time.toFixed(2)} minut</p>
      <p>Od: {moment(toIsoString(upperTimestamp)).toLocaleString()}</p>
      <p>Do: {moment(toIsoString(lowerTimestamp)).toLocaleString()}</p>
    </div>
  );
};

interface IDeviceStatisticsState {
  from: moment.Moment;
  to: moment.Moment;
  disabled: boolean;
}

class DeviceStatistics extends React.Component<{}, IDeviceStatisticsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      disabled: false,
      from: moment().subtract(30, 'days'),
      to: moment(),
    };

    this.handleSelectFrom = this.handleSelectFrom.bind(this);
    this.handleSelectTo = this.handleSelectTo.bind(this);
    this.changeFromHour = this.changeFromHour.bind(this);
    this.changeToHour = this.changeToHour.bind(this);
  }

  public render() {
    return (
      <GetFlowDataMutation mutation={getFlowDataQuery}>
        {(sendMutation, { data, called, error, loading }) => (
          <div>
            <Form
              initialValues={{
                property: 'cerpadlo_mlzeni',
              }}
              // tslint:disable-next-line:jsx-no-lambda
              onSubmit={async (queryData: any) => {
                const { from, to } = this.state;
                const variables = {
                  limit: from.toISOString(),
                  offset: to.toISOString(),
                  property: queryData.property,
                };
                console.log(variables);
                sendMutation({
                  variables,
                });
              }}
              // tslint:disable-next-line:jsx-no-lambda
              render={({ handleSubmit, pristine, invalid }) => (
                <BForm onSubmit={handleSubmit}>
                  <InputGroup size="sm" style={{ paddingBottom: '0.5em' }}>
                    <InputGroupAddon addonType="prepend">
                      Hodnota
                    </InputGroupAddon>
                    <Field
                      value="cerpadlo_mlzeni"
                      name="property"
                      component="select"
                    >
                      <option value="cerpadlo_mlzeni">Cerpadlo v chodu</option>
                      <option value="ventil_profuku">
                        Ventil profuk otevren
                      </option>
                      <option value="rezim_servis_DT1">rezim_servis_DT1</option>
                      <option value="material_na_presypu">
                        Materiál na přesypu
                      </option>
                      <option value="napajeni">Napájení</option>
                      <option value="tlak_cerpadlo_mlzeni">
                        Dosažen min. tlak za čerpadlem
                      </option>
                      <option value="ventil_profuku">
                        Ventil profuku otevřen
                      </option>
                      <option value="ventil_profuku_tlak">
                        Dosažen min. tlak za ventilem profuku
                      </option>
                      <option value="neni_material_presyp">
                        Překročen čas čekání na materiál
                      </option>
                      <option value="tlak_vody_mlzeni">
                        Překročen max. tlak za čerpadlem
                      </option>
                      <option value="tlak_vzduchu_profuk">
                        Zimní opatření
                      </option>
                    </Field>
                  </InputGroup>
                  <InputGroup style={{ paddingBottom: '0.5em' }}>
                    <InputGroupAddon addonType="prepend">Od</InputGroupAddon>
                    <Datetime
                      onChange={this.handleSelectFrom}
                      value={this.state.from}
                    />
                  </InputGroup>
                  <InputGroup style={{ paddingBottom: '0.5em' }}>
                    <InputGroupAddon addonType="prepend">Do</InputGroupAddon>
                    <Datetime
                      onChange={this.handleSelectTo}
                      value={this.state.to}
                    />
                  </InputGroup>
                  {this.state.disabled && (
                    <p style={{ color: 'red' }}>Špatný rozsah data</p>
                  )}
                  <br />
                  <p>
                    <Button
                      disabled={this.state.disabled}
                      size="sm"
                      color="primary"
                      type="submit"
                    >
                      Vypočítat
                    </Button>
                  </p>
                </BForm>
              )}
            />
            {data && !error && !loading && (
              <DisplayResult
                value={data.getFlowData.time}
                upperTimestamp={data.getFlowData.endTimestamp}
                lowerTimestamp={data.getFlowData.startTimestamp}
              />
            )}
          </div>
        )}
      </GetFlowDataMutation>
    );
  }

  private changeFromHour(time: moment.Moment) {
    const { from } = this.state;

    from.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });

    this.setState({
      from,
    });
    this.invalidateDate(time, this.state.to);
  }
  private changeToHour(time: moment.Moment) {
    const { to } = this.state;

    to.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
    });

    this.setState({
      to,
    });
    this.invalidateDate(time, this.state.to);
  }

  private handleSelectFrom(date: moment.Moment | string) {
    if (typeof date !== 'string') {
      this.setState({
        from: date,
      });
    }

    this.invalidateDate(moment(date), moment(this.state.to));
  }
  private handleSelectTo(date: moment.Moment | string) {
    if (typeof date !== 'string') {
      this.setState({
        to: date,
      });
    }
    this.invalidateDate(moment(this.state.from), moment(date));
  }
  private invalidateDate(from: moment.Moment, to: moment.Moment) {
    const isAfter = from.isAfter(to);
    this.setState({ disabled: isAfter });
  }
}

export default DeviceStatistics;
