import * as React from 'react';
import { Card } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';

import DeviceStatistics from '../../Device/DeviceStatistics/getDeviceStatistics';
import { statisticsStyle } from './constants';

export const Statistics: React.SFC = () => (
  <Card style={statisticsStyle}>
    <CardHeader tag="h5">Statistiky</CardHeader>
    <CardBody className="d-flex flex-column">
      <DeviceStatistics />
    </CardBody>
  </Card>
);
