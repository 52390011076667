import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { getFlowData, getFlowDataVariables } from './__generated__/getFlowData';

export const getFlowDataQuery = gql`
  mutation getFlowData(
    $property: StatusProperties!
    $offset: String!
    $limit: String!
  ) {
    getFlowData(property: $property, offset: $offset, limit: $limit) {
      startTimestamp
      endTimestamp
      time
    }
  }
`;

class GetFlowDataMutation extends Mutation<getFlowData, getFlowDataVariables> {}

export default GetFlowDataMutation;
