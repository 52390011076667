import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  sendSetValueCommand,
  sendSetValueCommandVariables,
} from './__generated__/sendSetValueCommand';

export const sendValueCommand = gql`
  mutation sendSetValueCommand(
    $device_id: Int!
    $command: SetValueTypes!
    $value: Int!
  ) {
    sendSetValueCommand(
      device_id: $device_id
      command: $command
      value: $value
    ) {
      device_id
      command
      value
    }
  }
`;

class SendValueCommandMutation extends Mutation<
  sendSetValueCommand,
  sendSetValueCommandVariables
> {}

export default SendValueCommandMutation;
