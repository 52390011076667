/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CommandTypes {
  AUTOMAT_VYP = 'AUTOMAT_VYP',
  AUTOMAT_ZAP = 'AUTOMAT_ZAP',
  MLZENI_VYP = 'MLZENI_VYP',
  MLZENI_ZAP = 'MLZENI_ZAP',
  PROFUK_VYP = 'PROFUK_VYP',
  PROFUK_ZAP = 'PROFUK_ZAP',
}

export enum Device2FloatCommands {
  SP_H2S_Kanal_BN1 = 'SP_H2S_Kanal_BN1',
  SP_KlapaVzduchBypassCisteni = 'SP_KlapaVzduchBypassCisteni',
  SP_KlapaVzduchBypassRegenerace = 'SP_KlapaVzduchBypassRegenerace',
  SP_KlapaVzduchInputCisteni = 'SP_KlapaVzduchInputCisteni',
  SP_KlapaVzduchInputRegenerace = 'SP_KlapaVzduchInputRegenerace',
  SP_VzduchOtackyCisteni = 'SP_VzduchOtackyCisteni',
  SP_VzduchOtackyRegenerace = 'SP_VzduchOtackyRegenerace',
}

export enum Device2IntCommands {
  Cisteni_Oxys = 'Cisteni_Oxys',
  Cisteni_Plaskat = 'Cisteni_Plaskat',
  Regenerace_Oxys = 'Regenerace_Oxys',
  Regenerace_Plaskat = 'Regenerace_Plaskat',
  SP_TimeCisteniStartHodina = 'SP_TimeCisteniStartHodina',
  SP_TimeCisteniStartMinuta = 'SP_TimeCisteniStartMinuta',
  SP_TimeCisteniStopHodina = 'SP_TimeCisteniStopHodina',
  SP_TimeCisteniStopMinuta = 'SP_TimeCisteniStopMinuta',
  SP_TimeRegenStartHodina = 'SP_TimeRegenStartHodina',
  SP_TimeRegenStartMinuta = 'SP_TimeRegenStartMinuta',
  SP_TimeRegenStopHodina = 'SP_TimeRegenStopHodina',
  SP_TimeRegenStopMinuta = 'SP_TimeRegenStopMinuta',
  Restart_automat = 'Restart_automat',
}

export enum Device2PulseCommands {
  RemoteStartRun = 'RemoteStartRun',
  RemoteStopRun = 'RemoteStopRun',
  Restart_automat = 'Restart_automat',
}

export enum SetFloatTypes {
  MOTOHODINY_PROFUK = 'MOTOHODINY_PROFUK',
  TEPLOTA_PROFUK = 'TEPLOTA_PROFUK',
  TEPLOTA_ZIMA = 'TEPLOTA_ZIMA',
}

export enum SetValueTypes {
  DOBA_MLZENI_VYP = 'DOBA_MLZENI_VYP',
  DOBA_MLZENI_ZAP = 'DOBA_MLZENI_ZAP',
  DOBA_PROFUK_ZAP = 'DOBA_PROFUK_ZAP',
}

export enum StatusProperties {
  cerpadlo_mlzeni = 'cerpadlo_mlzeni',
  material_na_presypu = 'material_na_presypu',
  napajeni = 'napajeni',
  neni_material_presyp = 'neni_material_presyp',
  rezim_servis_DT1 = 'rezim_servis_DT1',
  timestamp = 'timestamp',
  tlak_cerpadlo_mlzeni = 'tlak_cerpadlo_mlzeni',
  tlak_vody_mlzeni = 'tlak_vody_mlzeni',
  tlak_vzduchu_profuk = 'tlak_vzduchu_profuk',
  ventil_profuku = 'ventil_profuku',
  ventil_profuku_tlak = 'ventil_profuku_tlak',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
