import * as React from 'react';
import { Card, Table } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';

import { RowBool, TabHeader, RowNumber } from './TableParts';
import { SendBoolCommand } from '../../Device/DeviceCommands/SendBoolCommand';
import { SendValueCommand } from '../../Device/DeviceCommands/SendValueCommand';
import CardHeader from 'reactstrap/lib/CardHeader';
import { cardStyle, titleOffOnStyle, controlsStyle } from './constants';
import {
  CommandTypes,
  SetValueTypes,
} from '../../../__generated__/globalTypes';

interface IFog {
  material_na_presypu: boolean;
  prekrocen_cas_cekani_na_material: boolean;
  cerpadlo_v_chodu: boolean;
  dosazen_min_tlak_za_cerpadlem: boolean;
  dosazen_max_tlak_za_cerpadlem: boolean;
  cas_mlzeni: number;
  cas_prodlevy: number;
  cas_mlzeni_setting: number;
  cas_prodlevy_setting: number;
  cekani_na_mat: number;
}
const Controls = () => (
  <tr>
    <td style={titleOffOnStyle}>Mlžení</td>
    <td style={controlsStyle}>
      <SendBoolCommand command={CommandTypes.MLZENI_ZAP} color="success" />
    </td>
    <td style={controlsStyle}>
      <SendBoolCommand command={CommandTypes.MLZENI_VYP} color="danger" />
    </td>
  </tr>
);

export function resolveRunning(cd: number, secondCd: number, waiting: number) {
  if (cd > 0 || secondCd > 0) {
    return 'info';
  } else {
    if (waiting > 0) {
      return 'warning';
    } else {
      return null;
    }
  }
}
export function resolveInverse(cd: number, secondCd: number, waiting: number) {
  if (cd > 0 || secondCd > 0 || waiting > 0) {
    return true;
  } else {
    return false;
  }
}
export const Fog: React.SFC<IFog> = ({
  material_na_presypu,
  prekrocen_cas_cekani_na_material,
  cerpadlo_v_chodu,
  dosazen_min_tlak_za_cerpadlem,
  dosazen_max_tlak_za_cerpadlem,
  cas_mlzeni,
  cas_prodlevy,
  cas_mlzeni_setting,
  cas_prodlevy_setting,
  cekani_na_mat,
}) => (
  <Card
    style={cardStyle}
    color={resolveRunning(cas_mlzeni, cas_prodlevy, cekani_na_mat)}
    inverse={resolveInverse(cas_mlzeni, cas_prodlevy, cekani_na_mat)}
  >
    <CardHeader tag="h5">Mlžení</CardHeader>
    <CardBody>
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Stav" />
          <RowBool title="Materiál na přesypu" status={material_na_presypu} />
          <RowBool
            title="Překročen čas čekání na materiál"
            status={prekrocen_cas_cekani_na_material}
          />
          <RowBool title="Čerpadlo v chodu" status={cerpadlo_v_chodu} />
          <RowBool
            title="Dosažen min. tlak za čerpadlem"
            status={dosazen_min_tlak_za_cerpadlem}
          />
          <RowBool
            title="Překročen max. tlak za čerpadlem"
            status={dosazen_max_tlak_za_cerpadlem}
          />
          <RowNumber
            title="Čas mlžení (s)"
            status={cas_mlzeni}
            info={{ unit: 's', value: cas_mlzeni_setting }}
          />
          <RowNumber
            title="Čas prodlevy (s)"
            status={cas_prodlevy}
            info={{ unit: 's', value: cas_prodlevy_setting }}
          />
          <RowNumber
            title="Čekání na materiál (s)"
            status={cekani_na_mat}
            info={{ unit: 's', value: 120 * 60 }}
          />
        </tbody>
      </Table>
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Nastavení" />
        </tbody>
      </Table>
      <SendValueCommand
        min={0}
        command={SetValueTypes.DOBA_MLZENI_ZAP}
        title="Čas mlžení (s)"
      />
      <SendValueCommand
        min={0}
        command={SetValueTypes.DOBA_MLZENI_VYP}
        title="Čas prodlevy (s)"
      />
      <br />
      <Table size="sm" borderless={true}>
        <tbody>
          <TabHeader title="Manuální ovládání" />
          <Controls />
        </tbody>
      </Table>
    </CardBody>
  </Card>
);
