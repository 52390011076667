import { IConfig } from "./types";

export const CONFIG: IConfig = {
  development: {
    ADDRESS: "http://localhost:5050/graphql"
  },
  production: {
    ADDRESS: "/graphql"
  },
  stagging: {
    ADDRESS: "/graphql"
  }
};
