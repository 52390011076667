import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  sendSetFloatCommand,
  sendSetFloatCommandVariables,
} from './__generated__/sendSetFloatCommand';

export const sendFloatCommand = gql`
  mutation sendSetFloatCommand(
    $device_id: Int!
    $command: SetFloatTypes!
    $value: Float!
  ) {
    sendSetFloatCommand(
      device_id: $device_id
      command: $command
      value: $value
    ) {
      device_id
      command
      value
    }
  }
`;

class SendFloatCommandMutation extends Mutation<
  sendSetFloatCommand,
  sendSetFloatCommandVariables
> {}

export default SendFloatCommandMutation;
