import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { ApolloProvider } from "react-apollo";
import { Apollo } from "./utils/Apollo";
import registerWorker from "./registerServiceWorker";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <ApolloProvider client={Apollo}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root") as HTMLElement
);
registerWorker();
