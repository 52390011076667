/* tslint-disable no-console */
import 'bootstrap/dist/css/bootstrap.min.css';

import * as React from 'react';
import './App.css';

import { LoginRoute } from './components/User/userLogin';
import { Device1 } from './components/Device';
import { Device2 } from './components/Device2';
import { Header } from './components/App';
import { Link, Route } from 'react-router-dom';

import * as moment from 'moment';

moment.locale('cs');

const Links: React.FC = () => (
  <div>
    <Link to="/device1">
      <h2>TFJM AMO</h2>
    </Link>
    <Link to="/device2">
      <h2>Ivancice</h2>
    </Link>
  </div>
);

class App extends React.Component {
  public render() {
    return (
      <div>
        <Header title="Ecocoal zarizeni 1" />
        <Route exact={true} path="/" component={Links} />
        <Route exact={true} path="/device1" component={Device1} />
        <Route exact={true} path="/device2" component={Device2} />
        <Route exact={true} path="/login" component={LoginRoute} />
      </div>
    );
  }
}

export default App;
