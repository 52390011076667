import * as React from 'react';
import { Card, Table, CardBody } from 'reactstrap';
import { Status } from '../../Device/DeviceStatus/BoolStatus';

interface IHeading {
  date: string;
  time: string;
  power: boolean;
  serviceMode: boolean;
}

function resolveColor(power: boolean, serviceMode: boolean) {
  if (power) {
    if (serviceMode) {
      return 'warning';
    } else {
      return 'success';
    }
  } else {
    return 'danger';
  }
}

export const Heading: React.SFC<IHeading> = ({
  date,
  power,
  serviceMode,
  time,
}) => (
  <Card
    inverse={true}
    color={resolveColor(power, serviceMode)}
    style={{ padding: '0rem', width: '100%' }}
  >
    <CardBody style={{ padding: '0.25rem', paddingLeft: '1.25rem' }}>
      <Table size="sm" borderless={true} style={{ marginBottom: 0 }}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h5>Mlžení TFJ AMO 1.0.0</h5>
            </td>
          </tr>
          <tr>
            <td>Datum: {date}</td>
            <td>Napájení: {<Status status={power} />}</td>
          </tr>
          <tr>
            <td>Čas: {time}</td>
            <td>Servisní režím: {<Status status={serviceMode} />}</td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  </Card>
);
