import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  setPulseDevice2CommandVariables,
  setPulseDevice2Command,
} from './__generated__/setPulseDevice2Command';

export const sendPulseCommandDevice2 = gql`
  mutation setPulseDevice2Command($command: Device2PulseCommands!) {
    setPulseDevice2Command(command: $command) {
      command
    }
  }
`;

class SendPulseCommandMutation extends Mutation<
  setPulseDevice2Command,
  setPulseDevice2CommandVariables
> {}

export default SendPulseCommandMutation;
