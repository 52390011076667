import * as React from 'react';

import gql from 'graphql-tag';
import { Mutation, graphql, FetchResult } from 'react-apollo';
import { User } from '../../types';

import { Form, Field, FieldRenderProps } from 'react-final-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Input,
  InputGroup,
  Button,
  Form as BForm,
  Container,
} from 'reactstrap';
import { logout } from '../../utils/Logout';
import { FormApi } from 'final-form';

const SEND_LOGIN = gql`
  mutation userLogin($username: String!, $password: String!) {
    userLogin(username: $username, password: $password) {
      token
    }
  }
`;

class UserLoginMutation extends Mutation<
  User.IUserLoginResponse,
  User.IUserLogin
> {}

interface ILogin {
  username?: string;
  password?: string;
}

const Password: React.SFC<FieldRenderProps<HTMLInputElement>> = ({
  input,
  meta,
  ...rest
}) => (
  <InputGroup>
    <Input placeholder="Heslo" type="password" {...input} {...rest} />
  </InputGroup>
);

const Login: React.SFC<FieldRenderProps<HTMLInputElement>> = ({
  input,
  meta,
  ...rest
}) => (
  <InputGroup>
    <Input placeholder="Uzivatelske jmeno" {...input} {...rest} />
  </InputGroup>
);
const handleLogin = (
  result: FetchResult<
    User.IUserLoginResponse,
    Record<string, any>,
    Record<string, any>
  > | void
) => {
  if (result) {
    localStorage.setItem('token', result.data.userLogin.token);
  }
};

const UserLogin: React.FunctionComponent<RouteComponentProps<void>> = ({
  history,
}) => {
  logout();
  return (
    <UserLoginMutation mutation={SEND_LOGIN}>
      {(userLogin, { data, called, error, loading }) => (
        <Container style={{ marginTop: '4em', maxWidth: '60%' }}>
          <h1>Přihlášení</h1>
          <Form
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={async (userData: ILogin, form: FormApi) => {
              const { username, password } = userData;
              userLogin({
                variables: { username, password },
              })
                .then(handleLogin)
                .then(() => history.push('/'))
                .catch(console.log);
            }}
            // tslint:disable-next-line:jsx-no-lambda
            render={({ handleSubmit, pristine, invalid }) => (
              <BForm onSubmit={handleSubmit}>
                <Field name="username" component={Login} />
                <br />
                <Field name="password" component={Password} />
                <br />
                <p>
                  <Button color="primary" className="float-right" type="submit">
                    Příhlásit se
                  </Button>
                </p>
              </BForm>
            )}
          />
          {error && <div style={{ color: 'red' }}>wrong credentials</div>}
        </Container>
      )}
    </UserLoginMutation>
  );
};

export const LoginRoute = graphql(SEND_LOGIN)(withRouter(UserLogin));
