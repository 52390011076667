import * as React from 'react';
import { Badge } from 'reactstrap';

interface IBoolStatus {
  title: string;
  status: boolean;
  unit?: string;
}

interface IStatus {
  status: boolean;
}

export const Status: React.SFC<IStatus> = ({ status }) => {
  if (status) {
    return (
      <span style={{ fontSize: '1rem' }}>
        <Badge color="success">ANO</Badge>
      </span>
    );
  } else {
    return (
      <span style={{ fontSize: '1rem' }}>
        <Badge color="danger">NE</Badge>
      </span>
    );
  }
};

export const BoolStatus: React.SFC<IBoolStatus> = ({ unit, status, title }) => (
  <tr>
    <td>{title}</td>
    <td>
      <Status status={status} />
    </td>
    {unit && <td>{unit}</td>}
  </tr>
);
