import * as React from 'react';
import { Status } from '../../Device/DeviceStatus/BoolStatus';
import { partTitleStyle } from './constants';
interface IRowNumber {
  title: string;
  status: number | string;
  info?: IInfo;
}
interface IInfo {
  message?: string;
  value?: number | string;
  unit?: string;
}

const InfoRow: React.SFC<{ info: IInfo }> = ({
  info: { message, value, unit },
}) => (
  <td>
    [{message ? message : null}
    {value ? value : null}
    {unit ? unit : null}]
  </td>
);

export const RowNumber: React.SFC<IRowNumber> = ({ title, status, info }) => (
  <tr>
    <td>{title}</td>
    {info && <InfoRow info={info} />}
    <td>{status}</td>
  </tr>
);

interface IRowBool {
  title: string;
  status: boolean;
}

export const RowBool: React.SFC<IRowBool> = ({ title, status }) => (
  <tr>
    <td>{title}</td>
    <td />
    <td>
      <Status status={status} />
    </td>
  </tr>
);

interface ITabHeader {
  title: string;
}
export const TabHeader: React.SFC<ITabHeader> = ({ title }) => (
  <tr>
    <td colSpan={3}>
      <strong style={partTitleStyle}>{title}</strong>
    </td>
  </tr>
);

interface IFakeRow {
  rows: number;
}

export const FakerRow: React.SFC<IFakeRow> = ({ rows }) => (
  <tbody>
    {Array.from({ length: rows }).map((item, index) => (
      <tr key={index}>
        <td />
      </tr>
    ))}
  </tbody>
);
