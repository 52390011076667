import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";

interface IHeader {
  title: string;
}

type PopsType = RouteComponentProps<void> & IHeader;

const HeaderBar: React.SFC<PopsType> = props => {
  const token = localStorage.getItem("token");
  const displayLogout = token !== undefined || token !== "";
  return (
    <Navbar color="dark" light={true} expand="md" className="navbar-dark">
      <NavbarBrand href="/">ECOCOAL-monitoring</NavbarBrand>
      <Nav className="ml-auto" navbar={true}>
        <NavItem>
          {displayLogout && <NavLink href="/login">Odhlasit se</NavLink>}
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export const Header = withRouter(HeaderBar);
