import * as moment from 'moment';

export function minuteParser(inputTime: number) {
  if (inputTime < 10) {
    return `0${inputTime}`;
  } else {
    return inputTime.toString();
  }
}

export function timePareserFunction(inputTime: number) {
  const parsedTime = moment.duration(inputTime, 'hours');
  const daysToHours = parsedTime.days() * 24 + parsedTime.hours();
  const parsedMinutes = minuteParser(parsedTime.minutes());
  return `${daysToHours}:${parsedMinutes}`;
}
