import * as React from 'react';
import { Form, Button } from 'reactstrap';
import SendIntCommandMutation, {
  sendIntCommandDevice2,
} from '../queries/sendIntCommand';
import { QueryResult } from 'react-apollo';
import { OperationVariables } from 'apollo-boost';
import { getDevice2 } from '../queries/__generated__/getDevice2';
import { Device2IntCommands } from '../../../__generated__/globalTypes';

export enum TurnStateCommand {
  on = 1,
  off = 0,
}

interface ISendTurnOnCommand {
  command: Device2IntCommands;
  title?: string;
  turnStateCommand: TurnStateCommand;
  query: QueryResult<getDevice2, OperationVariables>;
  color?: 'danger' | 'success';
}
interface ICommandState {
  disabled: boolean;
}

class CommandComp extends React.Component<ISendTurnOnCommand, ICommandState> {
  constructor(props: ISendTurnOnCommand) {
    super(props);
    this.state = {
      disabled: false,
    };

    this.functionResolveDisabled = this.functionResolveDisabled.bind(this);
  }

  private functionResolveDisabled(state: boolean) {
    this.setState({ disabled: state });
  }

  public render() {
    const { command, title, color } = this.props;
    const { disabled } = this.state;
    return (
      <SendIntCommandMutation mutation={sendIntCommandDevice2}>
        {sendSetFloatCommand => (
          <Form
            // tslint:disable-next-line jsx-no-lambda
            onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
              this.setState({ disabled: true });
              e.preventDefault();
              this.functionResolveDisabled(true);
              const sendValue = this.props.turnStateCommand;
              sendSetFloatCommand({
                variables: {
                  command,
                  value: sendValue,
                },
              })
                .then(() => {
                  setTimeout(() => {
                    this.functionResolveDisabled(false);
                  }, 2000);
                })
                .catch(() => {
                  this.functionResolveDisabled(false);
                });
            }}
            style={{
              marginBottom: '0.5em',
              marginTop: '0.5em',
            }}
          >
            <Button
              color={color ? color : 'primary'}
              disabled={disabled}
              type="submit"
              size="sm"
              style={{ width: '100%' }}
            >
              {title}
            </Button>
          </Form>
        )}
      </SendIntCommandMutation>
    );
  }
}
export const SendTurnStateCommand = CommandComp;
