export const cardStyle: React.CSSProperties = {
  maxWidth: '100vw',
  minWidth: '405px',
};
export const statisticsStyle: React.CSSProperties = {
  maxWidth: '32.5vw',
  minWidth: '405px',
};

export const titleOffOnStyle: React.CSSProperties = {
  verticalAlign: 'middle',
};

export const partTitleStyle: React.CSSProperties = {
  textDecoration: 'underline',
};

export const controlsStyle: React.CSSProperties = {
  width: '5rem',
};
