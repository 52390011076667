import * as React from 'react';
import {
  Form,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import SendFloatCommandMutation, {
  sendFloatCommandDevice2,
} from '../queries/sendFloatCommand';
import { Device2FloatCommands } from '../../../__generated__/globalTypes';
import { QueryResult } from 'react-apollo';
import { OperationVariables } from 'apollo-boost';
import { getDevice2 } from '../queries/__generated__/getDevice2';
import {
  APPEND_TEXT,
  APPEND_BUTTON,
  PREPEND_TEXT,
  MIDDLE_INPUT,
} from './styles';

interface ISendIntCommand {
  command: Device2FloatCommands;
  precision?: number;
  title?: string;
  min?: number;
  max?: number;
  query?: QueryResult<getDevice2, OperationVariables>;
  unit: string;
}
interface ICommandState {
  input: HTMLInputElement | null;
  disabled: boolean;
}

interface QueryValue {
  query?: QueryResult<getDevice2, OperationVariables>;
  unit: string;
  mesurement: string;
}
const QueryValue: React.FC<QueryValue> = ({ query, mesurement, unit }) => {
  if (query.loading) {
    return <span>...</span>;
  }
  if (query.error) {
    return <span>chyba...</span>;
  }
  if (query.data) {
    const item = query.data.getDevice2Floats.find(
      item => item.ValueID === mesurement
    );
    return item ? (
      <div>
        {item.value} {unit}
      </div>
    ) : (
      <div />
    );
  }
};

class CommandComp extends React.Component<ISendIntCommand, ICommandState> {
  constructor(props: ISendIntCommand) {
    super(props);
    this.setNode = this.setNode.bind(this);
    this.state = {
      input: null,
      disabled: false,
    };
  }

  public render() {
    const { command, precision, title, min, query, max, unit } = this.props;
    const { input } = this.state;
    const step = precision === undefined ? 1 : precision;
    return (
      <SendFloatCommandMutation mutation={sendFloatCommandDevice2}>
        {sendSetFloatCommand => (
          <Form
            // tslint:disable-next-line jsx-no-lambda
            onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
              this.setState({ disabled: true });
              e.preventDefault();
              const sendValue = parseFloat(input.value);
              if (!isNaN(sendValue)) {
                sendSetFloatCommand({
                  variables: {
                    command,
                    value: sendValue,
                  },
                })
                  .then(() => {
                    setTimeout(() => {
                      this.setState({ disabled: false });
                    }, 7500);
                  })
                  .catch(() => {
                    this.setState({ disabled: false });
                  });
                // set defaut value
                input.value = null;
                this.setState({
                  input,
                });
              }
            }}
            style={{
              marginBottom: '0.5em',
              marginTop: '0.5em',
            }}
          >
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={PREPEND_TEXT}>
                  {title || command}
                </InputGroupText>
              </InputGroupAddon>
              <Input
                innerRef={this.setNode}
                style={MIDDLE_INPUT}
                type="number"
                step={step}
                min={min}
                max={max}
              />
              <InputGroupAddon addonType="append">
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.disabled}
                  style={APPEND_BUTTON}
                >
                  Nastavit
                </Button>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <InputGroupText style={APPEND_TEXT}>
                  {query && (
                    <QueryValue
                      query={query}
                      mesurement={command}
                      unit={unit}
                    />
                  )}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
        )}
      </SendFloatCommandMutation>
    );
  }

  private setNode(input: HTMLInputElement) {
    this.setState({ input });
  }
}
export const SendFloatCommand = CommandComp;
