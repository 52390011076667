import * as React from 'react';
import { SendFloatCommand } from './commands/SendFloatCommand';
import { SendPulseCommand } from './commands/SendPulseCommand';
import { QueryValue, SendIntCommand } from './commands/SendIntCommand';
import {
  Device2FloatCommands,
  Device2IntCommands,
  Device2PulseCommands,
} from '../../__generated__/globalTypes';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Redirect } from 'react-router';
import GetDevice2FloatsQuery, {
  getDevice2FloatsQuery,
} from './queries/getFloatValues';
import { OperationVariables, QueryResult } from 'react-apollo';
import { getDevice2 } from './queries/__generated__/getDevice2';
import { CARD_STYLE } from './commands/styles';
import moment from 'moment';
import 'moment/locale/cs';
import {
  SendTurnStateCommand,
  TurnStateCommand,
} from './commands/SendTurnOnCommand';

interface DeviceLayoutProps {
  query: QueryResult<getDevice2, OperationVariables>;
}

const TurnOnButtons: React.FC = () => (
  <Card style={CARD_STYLE}>
    <CardBody>
      <CardHeader>
        <h4>RemoteStart</h4>
      </CardHeader>
      <Row>
        <Col>
          <SendPulseCommand
            command={Device2PulseCommands.RemoteStartRun}
            color="success"
          />
        </Col>
        <Col>
          <SendPulseCommand
            command={Device2PulseCommands.RemoteStopRun}
            color="danger"
          />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

interface DeviceLayoutState {
  block: Device2IntCommands[];
}

class DeviceLayout extends React.Component<
  DeviceLayoutProps,
  DeviceLayoutState
> {
  state: DeviceLayoutState = {
    block: [],
  };
  constructor(props: DeviceLayoutProps) {
    super(props);
    this.onSubmitSwitch = this.onSubmitSwitch.bind(this);
  }

  private onSubmitSwitch(command: Device2IntCommands, state: TurnStateCommand) {
    if (state === TurnStateCommand.on) {
      this.setState({ block: [...this.state.block, command] });
    }
    if (state === TurnStateCommand.off) {
      this.setState({
        block: this.state.block.filter(item => item !== command),
      });
    }
  }

  render() {
    const { query } = this.props;
    return (
      <Container>
        <Card style={CARD_STYLE}>
          <CardHeader>
            <Row>
              <Col>
                <h4>Regenerace</h4>
              </Col>
              <Col>
                <Time />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Card>
              <CardBody>
                <h5>Nastavené hodnoty</h5>
                <SendFloatCommand
                  command={Device2FloatCommands.SP_VzduchOtackyRegenerace}
                  query={query}
                  unit="Hz"
                />
                <SendFloatCommand
                  command={Device2FloatCommands.SP_KlapaVzduchBypassRegenerace}
                  query={query}
                  unit="%"
                />
                <SendFloatCommand
                  command={Device2FloatCommands.SP_KlapaVzduchInputRegenerace}
                  query={query}
                  unit="%"
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h5>Start regenerace</h5>
                <Row>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeRegenStartHodina}
                      query={query}
                      unit="hod"
                    />
                  </Col>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeRegenStartMinuta}
                      query={query}
                      unit="min"
                    />
                  </Col>
                </Row>
                <h5>Stop regenerace</h5>
                <Row>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeRegenStopHodina}
                      query={query}
                      unit="hod"
                    />
                  </Col>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeRegenStopMinuta}
                      query={query}
                      unit="min"
                    />
                  </Col>
                </Row>
                <h5>Ovldádání oxys</h5>
                <Row>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Regenerace_Oxys}
                      turnStateCommand={TurnStateCommand.on}
                      color="success"
                      title="Zapnout Oxys Regenerace"
                      query={query}
                    />
                  </Col>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Regenerace_Oxys}
                      turnStateCommand={TurnStateCommand.off}
                      color="danger"
                      title="Vypnout Oxys Regenrace"
                      query={query}
                    />
                  </Col>
                </Row>
                <h5>Ovládání plaskat</h5>
                <Row>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Regenerace_Plaskat}
                      turnStateCommand={TurnStateCommand.on}
                      color="success"
                      title="Zapnout Plaskat Regenerace"
                      query={query}
                    />
                  </Col>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Regenerace_Plaskat}
                      turnStateCommand={TurnStateCommand.off}
                      color="danger"
                      title="Vypnout Plaskat Regenerace"
                      query={query}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
        <Card style={CARD_STYLE}>
          <CardHeader>
            <Row>
              <Col>
                <h4>Čištění</h4>
              </Col>
              <Col>
                <Time />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Card>
              <CardBody>
                <h5>Nastavené hodnoty</h5>
                <SendFloatCommand
                  command={Device2FloatCommands.SP_VzduchOtackyCisteni}
                  query={query}
                  unit="Hz"
                />
                <SendFloatCommand
                  command={Device2FloatCommands.SP_KlapaVzduchBypassCisteni}
                  query={query}
                  unit="%"
                />
                <SendFloatCommand
                  command={Device2FloatCommands.SP_KlapaVzduchInputCisteni}
                  query={query}
                  unit="%"
                />
                <SendFloatCommand
                  command={Device2FloatCommands.SP_H2S_Kanal_BN1}
                  title="Mez H2S pro sepnutí číštění"
                  query={query}
                  unit="ppm"
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h5>Start čištění</h5>
                <Row>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeCisteniStartHodina}
                      query={query}
                      unit="hod"
                      min={0}
                      max={23}
                    />
                  </Col>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeCisteniStartMinuta}
                      query={query}
                      unit="min"
                      min={0}
                      max={59}
                    />
                  </Col>
                </Row>
                <h5>Stop čištění</h5>
                <Row>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeCisteniStopHodina}
                      query={query}
                      unit="hod"
                      min={0}
                      max={23}
                    />
                  </Col>
                  <Col>
                    <SendIntCommand
                      command={Device2IntCommands.SP_TimeCisteniStopMinuta}
                      query={query}
                      unit="min"
                      min={0}
                      max={59}
                    />
                  </Col>
                </Row>
                <h5>Ovládání Oxys</h5>
                <Row>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Cisteni_Oxys}
                      turnStateCommand={TurnStateCommand.on}
                      color="success"
                      title="Zapnout Oxys Čištění"
                      query={query}
                    />
                  </Col>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Cisteni_Oxys}
                      turnStateCommand={TurnStateCommand.off}
                      color="danger"
                      title="Vypnout Oxys Čištění"
                      query={query}
                    />
                  </Col>
                </Row>
                <h5>Ovládání plaskat</h5>
                <Row>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Cisteni_Plaskat}
                      turnStateCommand={TurnStateCommand.on}
                      color="success"
                      title="Zapnout Plaskat Čištění"
                      query={query}
                    />
                  </Col>
                  <Col>
                    <SendTurnStateCommand
                      command={Device2IntCommands.Cisteni_Plaskat}
                      turnStateCommand={TurnStateCommand.off}
                      color="danger"
                      title="Vypnout Plaskat Čištění"
                      query={query}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
        <TurnOnButtons />
        <Card style={CARD_STYLE}>
          <CardBody>
            <CardHeader>
              <h4>Restart automat</h4>
            </CardHeader>
            <Row>
              <Col>
                <SendPulseCommand
                  command={Device2PulseCommands.Restart_automat}
                  color="success"
                />
              </Col>
              <Col>
                {query && (
                  <QueryValue
                    query={query}
                    mesurement={Device2IntCommands.Restart_automat}
                    unit={'stav'}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

interface TimeState {
  time: Date;
}
class Time extends React.Component<{}, TimeState> {
  state: TimeState = {
    time: new Date(),
  };
  componentDidMount() {
    setInterval(() => {
      this.setState({ time: new Date() });
    }, 1000);
  }
  render() {
    return (
      <h4>
        {moment(this.state.time.toISOString())
          .locale('cs')
          .format('LTS')}
      </h4>
    );
  }
}

export class Device2Commands extends React.Component {
  render() {
    return (
      <GetDevice2FloatsQuery query={getDevice2FloatsQuery} pollInterval={1000}>
        {query => {
          console.log({ result: query.data || {} });
          return <DeviceLayout query={query} />;
        }}
      </GetDevice2FloatsQuery>
    );
  }
}

export const Device2: React.FC = () => {
  const token = localStorage.getItem('token');
  return token ? <Device2Commands /> : <Redirect to="/login" />;
};
