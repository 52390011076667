import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  setFloatDevice2CommandVariables,
  setFloatDevice2Command,
} from './__generated__/setFloatDevice2Command';

export const sendFloatCommandDevice2 = gql`
  mutation setFloatDevice2Command(
    $command: Device2FloatCommands!
    $value: Float!
  ) {
    setFloatDevice2Command(command: $command, value: $value) {
      command
      value
    }
  }
`;

class SendFloatCommandMutation extends Mutation<
  setFloatDevice2Command,
  setFloatDevice2CommandVariables
> {}

export default SendFloatCommandMutation;
