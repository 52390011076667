import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  sendBoolCommand,
  sendBoolCommandVariables,
} from './__generated__/sendBoolCommand';

export const SEND_COMMAND = gql`
  mutation sendBoolCommand($device_id: Int!, $command: CommandTypes!) {
    sendBoolCommand(device_id: $device_id, command: $command) {
      device_id
      command
    }
  }
`;

class SendBoolCommandMutation extends Mutation<
  sendBoolCommand,
  sendBoolCommandVariables
> {}

export default SendBoolCommandMutation;
