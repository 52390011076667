import * as React from 'react';
import { Form, Button } from 'reactstrap';
import SendBoolCommandMutation, {
  SEND_COMMAND,
} from '../queries/sendBoolCommand';
import { buttonStyle } from './constants';
import { CommandTypes } from '../../../__generated__/globalTypes';

type Colors = 'danger' | 'success';

interface ICommandState {
  disabled: boolean;
}

interface ISendBoolCommand {
  command: CommandTypes;
  color?: Colors;
}

class Command extends React.Component<ISendBoolCommand, ICommandState> {
  constructor(props: ISendBoolCommand) {
    super(props);
    this.state = {
      disabled: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.logProperty = this.logProperty.bind(this);
  }
  public render() {
    const { command, color } = this.props;
    const { disabled } = this.state;

    return (
      <SendBoolCommandMutation mutation={SEND_COMMAND}>
        {sendBoolCommand => (
          <Form
            style={buttonStyle}
            // tslint:disable-next-line jsx-no-lambda
            onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
              this.handleClick();
              e.preventDefault();
              sendBoolCommand({
                variables: {
                  command,
                  device_id: 1,
                },
              });
            }}
          >
            <Button
              color={color ? color : 'primary'}
              disabled={disabled}
              type="submit"
              onMouseOver={this.logProperty}
              size="sm"
            >
              {color === 'danger' && 'Vypnout'}
              {color === 'success' && 'Zapnout'}
              {color === undefined && 'Nastavit'}
            </Button>
          </Form>
        )}
      </SendBoolCommandMutation>
    );
  }
  private handleClick() {
    if (!this.state.disabled) {
      this.setState({
        disabled: true,
      });
      setTimeout(this.enableButton, 2500);
    }
  }

  private logProperty() {
    console.log(this.props.command);
  }

  private enableButton() {
    this.setState({
      disabled: false,
    });
  }
}

export const SendBoolCommand = Command;
