import * as React from 'react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { gql } from 'apollo-boost';
import { DeviceStateDisplay } from './DeviceStateDisplay';
import { DeviceStates } from '../../../types';

const QUERY = gql`
  query currentDevState {
    currentDevState {
      timestamp
      temp
      status {
        material_na_presypu
        napajeni
        rezim_servis_DT1
        ventil_profuku
        cerpadlo_mlzeni
        tlak_cerpadlo_mlzeni
        ventil_profuku_tlak
      }
      settings {
        doba_mlzeni_zap
        doba_mlzeni_vyp
        doba_profuk_zap
      }
      float_settings {
        teplota_zima
        motohodiny_profuk
        motohodiny_stav
        teplota_profuk
      }
      errors {
        tlak_vzduchu_profuk
        tlak_vody_mlzeni
        neni_material_presyp
      }
      timers {
        mlzeni_zap
        mlzeni_vyp
        profuk_zap
        cekani_na_mat
      }
    }
  }
`;

class DeviceStateQuery extends Query<DeviceStates.IDeviceQueryProps> {}

export const DeviceState = () => {
  return (
    <DeviceStateQuery query={QUERY} pollInterval={1000}>
      {({ loading, data, error }) => {
        if (error) {
          console.log(error.message);
          if (error.message === 'GraphQL error: Not authorized') {
            return <Redirect to="/login" />;
          }
        }
        return (
          !loading &&
          !error && (
            <DeviceStateDisplay currentDevState={data.currentDevState} />
          )
        );
      }}
    </DeviceStateQuery>
  );
};

export default DeviceState;
