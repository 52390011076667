import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
  setIntDevice2Command,
  setIntDevice2CommandVariables,
} from './__generated__/setIntDevice2Command';

export const sendIntCommandDevice2 = gql`
  mutation setIntDevice2Command($command: Device2IntCommands!, $value: Int!) {
    setIntDevice2Command(command: $command, value: $value) {
      command
      value
    }
  }
`;

class CommandMutation extends Mutation<
  setIntDevice2Command,
  setIntDevice2CommandVariables
> {}

export default CommandMutation;
